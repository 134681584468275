import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"




const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    width: "100% !important",
    height: "76vh",
    [theme.breakpoints.down("sm")]: {
     
      height: 340,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 20,
    bottom: 20,
    backgroundSize: "contain",
    backgroundPosition: "center 40%",
  },
})

function EMSellaGegenInkontinenz(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-MIT-EMSELLA" aria-label="EMSCULPT und EMSELLA gegen Inkontinenz und für Mommy Makeover">
        <Typography
          variant="h4"
          marked="center"
          /* className={classes.title} */
          component="h2"
        >
      Behandlung mit BTL EMsella gegen Inkontinenz und effektives "Mommy Makeover"
        </Typography>
        <article>
      <Typography  component={'article'} variant={'body2'}  align="justify">
        <div      
          className={classes.imageWrapper}
        >
          <Image src="BTL_Emsella_PIC_9_International-pack-EN100_IGFB.png" alt="EMSELLA bei Inkontinenz" className={classes.imageSrc}/>
       </div> 
      
       <strong>BTL EMSELLA</strong> ermöglicht die <strong>nicht-invasive</strong> Behandlung von Inkontinenz bei Frauen und Männern. <strong>EMSELLA</strong> Diese einzigartige Technologie revolutioniert das Wohlbefinden von Frauen und Männern und schafft damit die Basis für gesundes 
       Selbstbewusstsein. Der Schlüsseleffekt von EMSELLA basiert auf fokussierter elektromagnetischer Energie. Eine einzelne EMSELLA-Sitzung erzeugt tausende von supramaximalen Kontraktionen der <strong>Beckenbodenmuskulatur</strong>, die für das Muskeltraining inkontinenter Patientinnen relevant sind.
       Somit ergänzt EMSELLA die Behandlung mit EMSCULPT und ermöglicht in der Kombination sehr gute Ergebnisse.
       <br/> <br/>
       Damit bilden EMSCULPT NEO und BTL EMSELLA in Kombination eine gute Basis für eine <strong>"Mommy Makerover"</strong> nach der <strong>Schwangerschaft</strong>, um Ihr altes Wohlbefinden
       schnell wieder herzustellen. 
       <br/> <br/>
       EMSELLA ist eine gute Wahl für Frauen und Männer jeden Alters, die eine Lösung für <strong>Harninkontinenz</strong> und eine Verbesserung ihrer Lebensqualität wünschen.
       Während der Behandlung können Sie angekleidet bleiben. Sie spüren dabei ein Kribbeln und Kontraktionen der <strong>Beckenbodenmuskulatur</strong>.
       Nach der Behandlung können Sie unmittelbar Ihre täglichen Aktivitäten wieder aufnehmen.
       Die Behandlung dauert wie beim EMSCULPT ungefähr 30 Minuten bei 2 Sitzungen pro Woche und insgesamt ungefähr 6 Sitzungen.
       <br/><br/>
       Mehr Informationen erhalten Sie beim Hersteller BTL unter "<a href="https://btlaesthetics.com/de/btl-emsella">EMSELLA SAGEN SIE NEIN ZU INKONTINEZ</a>".
       
      </Typography>
      </article>
     </Container>
  )
}

EMSellaGegenInkontinenz.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EMSellaGegenInkontinenz)
