import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"





const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "38vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 20,
    bottom: 20,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoGegenSchnarchen(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-Schlafapnoe" aria-label="Gegen Schnarchen & Schlafapnoe – Ursachen und Risiken">
        <Typography
          variant="h4"
          marked="center"
         /*  className={classes.title} */
          component="h2"
        >
        Gegen Schnarchen & Schlafapnoe – Ursachen und Risiken
        </Typography>
        <article>
      <Typography align="justify">
{/*         <div      
          className={classes.imageWrapper}
        >
          <Image src="Emsculpt_SIXPACK_WOMAN.png" alt="EMSCULPT NEO bei einer Rektusdiastase " className={classes.imageSrc}/>
       </div>  */}
      
      Chronische Herz- oder Lungenerkrankungen sowie hohe Blutzuckerwerte werden durch Bauchfett begünstigt. In Folge dieser Erkrankungen aber auch direkt wegen der mechanischen Behinderung der Atemmechanik kann eine Schlafapnoe entstehen.
      <br/><br/>
      Bei schnarchenden Menschen und Patienten mit Schlafapnoe lässt die Spannkraft der Muskulatur so stark nach, dass die Atemwege durch die erschlafften Muskeln verengt werden. Das beeinträchtigt die Atmung erheblich.
      <br/><br/>
      Zusätzlich fördert starkes Übergewicht Atemstillstände während des Schlafens. Dies kann durch den Druck vom Bauch auf den Brustkorb noch verstärkt werden. 
      <br/><br/>
      Eine Behandlung mit EMSCULPT NEO® kann durch den gezielten Fettabbau somit positiven Einfluss auf Ihre Gesundheit während der Schlafphase haben.


      </Typography>
      </article>
     </Container>
  )
}

EmsculptNeoGegenSchnarchen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoGegenSchnarchen)
