import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"




const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "38vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 20,
    bottom: 20,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoRektusdiastase(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-Rektusdiastase" aria-label="Korrektur der Stellung der geraden Bauchmuskeln mit EMSCULPT NEO bei Rektusdiastase">
        <Typography
          variant="h4"
          marked="center"
          /* className={classes.title} */
          component="h2"
        >
      Gegen Auseinanderstehen der geraden Bauchmuskeln nach der Geburt oder einer Diät (Rektusdiastase)
        </Typography>
        <article>
      <Typography  component={'article'} variant={'body2'}  align="justify">
        <div      
          className={classes.imageWrapper}
        >
          <Image src="Emsculpt_SIXPACK_WOMAN.png" alt="EMSCULPT NEO bei einer Rektusdiastase " className={classes.imageSrc}/>
       </div> 
      
       Bei einer Rektusdiastase stehen die geraden Bauchmuskeln auseinander, wodurch ein Mittellinienbruch entsteht. Eine Ursache dafür ist eine Bauchwandschwäche. Diese kann durch eine Schwangerschaft, Übergewicht oder falsches bzw. übertriebenes Training bedingt sein. Dabei ist eine Rektusdiastase im Zuge einer Schwangerschaft in der zweiten Hälfte, kurz vor der Geburt, normal. Diese wird durch Hormonausschüttungen hervorgerufen, die zu diversen, für die Geburt notwendigen, körperlichen Veränderungen führen. So wird das Bindegewebe erweicht, sodass die geraden Bauchmuskeln überdehnt und verlängert werden. Nach der Geburt finden die geraden Bauchmuskeln normalerweise wieder zusammen und die Rektusdiastase bildet sich zurück.
       <br/> <br/>
       Leider ist dies nicht immer der Fall, so dass als Folge eine unschöne Vorwölbung des Bauches auftritt, die oft trotz des regelmäßigen und intensiven Trainings der Bauchmuskulatur nicht im gewünschten Maße zurückgeht. 
        <br/> <br/>
       Unabhängig von der Ursache kann EMSCULPT NEO bei der Rektusdiastase eine wertvolle therapeutische Option sein, da dank des intensiven Trainingsniveaus während einer Sitzung ein Zusammenfügen der Muskelbäuche ermöglicht wird.  Regelmäßige Gymnastik und  Sport unterstützen den Vorgang und tragen dazu bei, das gewünschte Ergebnis nachhaltig zu erzielen. Dabei sind alle Übungen der geraden Bauchmuskeln zu vermeiden, da diese die Symptome verschlimmern können. Da die Beckenbodenmuskulatur hierbei extrem wichtig ist, empfehlen wir zusätzlich eine Behandlungsserie mit dem EMSELLA-Stuhl.


      </Typography>
      </article>
     </Container>
  )
}

EmsculptNeoRektusdiastase.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoRektusdiastase)
