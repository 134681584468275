import React from 'react';
import PropTypes from "prop-types"
import Carousel from "react-material-ui-carousel"
import { withStyles } from "@material-ui/core/styles"

import Image from "./Image"


const styles = theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(9),
    },
    images: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexWrap: "wrap",
    },
    imageWrapper: {
      position: "relative",
      display: "block",
      padding: 0,
      borderRadius: 0,
      //height: "100vh",
      height: "80vh",
      width:"auto",
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: 240,
      },
      "&:hover": {
        zIndex: 1,
      },
/*       "&:hover $imageBackdrop": {
        opacity: 0.15,
      },
      "&:hover $imageMarked": {
        opacity: 0,
      },
      "&:hover $imageTitle": {
        border: "4px solid currentColor",
      }, */
    },
    imageSrc: {
       position: "absolute !important",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%", 
    },

  })

  
function EmsculptNeoVorherNachherCarousel(props)
{
    const { classes } = props
    var items = [
        {
            id:"1",
            name: "Vorher_Nacherher Po",
            description: "EMSCULPT NEO: Vorher_Nacherher Po",
            fname:"Emsculpt_Neo_POST_April-Calendar_02042021_EN100_IGFB.png"

        },
        {
            id:"2",
            name: "Vorher-Nachher Bauch",
            description: "EMSCULPT NEO: Vorher-Nachher, Bauch, Mann",
            fname:"Emsculpt_Neo_POST_January-Calendar_17012021_EN100_IGFB.png"

        },        
        {
            id:"3",
            name: "Vor- und Nachher Reiterhosen (Saddlebags)",
            description: "Vor- und Nachher Reiterhosen (Saddlebags), Frau",
            fname:"Emsculpt_Neo_POST_New-Body-Parts-Thighs_EN100.png"

        },
        {
            id:"4",
            name: "Vor- und Nachher Innenschenkel",
            description: "EMSCULPT NEO: Effektiv Abnehmen und Jahre an Jugend zurück gewinnen",
            fname:"Emsculpt_Neo_POST_New-Body-Parts-Thighs-2_EN100.png"
        },        
        {
            id:"5",
            name: "Vor- und Nachher Innenschenkel(von hinten)",
            description: "Vor- und Nachher Innenschenkel(von hinten)",
            fname:"Emsculpt_Neo_POST_New-Body-Parts-Thighs-3_EN100.png"
        },
        {
            id:"6",
            name: "Vor- und Nachher Reiterhosen, leicht (vorne)",
            description: "Vor- und Nachher Reiterhosen, leicht (vorne)",
            fname:"Emsculpt_Neo_POST_New-Body-Parts-Thighs-4_EN100.png"
            
        },
        {
          id:"7",
          name: "Vorher-Nachher Bauch",
          description: "Vorher-Nachher Bauch",
          fname:"Emsculpt_Neo_POST_November-Calendar_10112020_EN100_IGFB.png"          
        },
        {
            id:"8",
            name: "Vorher-Nachher Bauch (definierter)",
            description: "Vorher-Nachher Bauch (definierter)",
            fname:"Emsculpt_Neo_POST_October-Calendar_25102020_EN100_IGFB.png"
        },
        {
            id:"9",
            name: "Vorher-Nachher Bauch (definierter), Frau",
            description: "Vorher-Nachher Bauch (definierter), Frau",
            fname:"Emsculpt_Neo_POST_October-Calendar_27102020_EN100_IGFB.png"
        },
        {
            id:"10",
            name: "Vorher-Nachher Bauch, Mann, Abnahmeeeffekt",
            description: "Vorher-Nachher Bauch, Mann, Abnahmeeeffekt",
            fname:"Emsculpt_Neo_POST_October-Calendar_28102020_EN100_IGFB.png"
        },
        {
            id:"11",
            name: "Vorher-Nacher Bauch Seite und Vorne, Frau",
            description: "Vorher-Nacher Bauch Seite und Vorne, Frau",
            fname:"Emsculpt_Neo_POST_October-Calendar_31102020_EN100_IGFB.png"
        },       
        {
          id:"12",
          name: "Vorher-Nacher Arme (EMSCULPT)",
          description: "Vorher-Nacher Arme (EMSCULPT)",
          fname:"Emsculpt_POST_October-Calendar_08102020_EN100.png"
        },               
    ]

    return (
        <Carousel 
            autoPlay="false" animation= "slide" indicators="false" interval="2000" navButtonsAlwaysVisible="false"
        >
            
            { items.map(item => (
                    <div height="80vh" key={item.id}>
                    <div      
                  className={classes.imageWrapper}
                >
                    {/* <Image src={item.fname} className={classes.imageSrc}/> */}
                    <Image src={item.fname} alt={item.name} style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }} />
                    </div>
                    </div>                
                ))
            }
            
        </Carousel>
    )
}


EmsculptNeoVorherNachherCarousel.propTypes = {
    classes: PropTypes.object.isRequired,
  }
  
  export default withStyles(styles)(EmsculptNeoVorherNachherCarousel)