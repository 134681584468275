import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"



const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    //   height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoBesteErgebnisse(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-BESTE-ERGEBNISSE" aria-label="EMSCULPT NEO: Wie erziele ich die besten Ergebnisse? ">
        <Typography
          variant="h4"
          marked="center"
         /*  className={classes.title} */
          component="h2"
        >
        Wie erziele ich den besten Behandlungs&shy;erfolg?
        </Typography>

      <article>
      <Typography  component={'article'} variant={'body2'}  align="justify">
      Die besten Ergebnisse erzielen Sie, wenn Sie sich parallel zur Behandlung gesund ernähren, regelmäßig bewegen und viel Wasser trinken. Mit dieser Kombination aus der Behandlung mit EMSCULPT NEO und einem gesunden Lebensstil erzielen Sie die besten Resultate. 

      Neben dem sichtbaren <strong>Fettabbau</strong> kommt es zur Reduktion des tief liegenden (viszeralen) Fettgewebes, welches ein bedeutsamer Risikofaktor für zahlreiche Erkrankungen ist wie z.B.: Bluthochdruck, Brustkrebs, Gebärmutterkrebs, Dickdarmkrebs, Herzinfarkt, Schlaganfall, Schlafapnoe oder Stoffwechselerkrankungen wie Diabetes.

Mit EMSCULPT NEO® können Sie nicht nur den Bauch und den Po in Form bringen. EMSCULPT NEO® verfügt über eine Reihe von  Applikatoren für die verschiedenen Körperzonen, die behandelt werden können:
<ol>
<li>Bauch</li>
<li>Po</li>
<li>Arme</li>
<li>Oberschenkel</li>
<li>Waden</li>
</ol>

      <br/> <br/><br/>
      </Typography>

      <div      
          className={classes.imageWrapper}
        >
          <Image src="Emsculpt_Neo_Behandlungs_Zonen.png" alt="Behandlungszonen EMSCULPT NEO-Bauch, Beine, Po, Arme" className={classes.imageSrc}/>
       </div>
      </article>
      

    </Container>
  )
}

EmsculptNeoBesteErgebnisse.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoBesteErgebnisse)
