import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"




const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "58vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 20,
    bottom: 20,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoBetterAging(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-ERFOLGREICH-BETTER-AGING" aria-label="Better Aging mit EMNSCULPT">
        <Typography
          variant="h4"
          marked="center"
          /* className={classes.title} */
          component="h2"
        >
       „Better Aging“ durch Erhalt der Muskulatur
        </Typography>
        <article>
      <Typography   component={'article'} variant={'body2'}   align="justify">
        <div      
          className={classes.imageWrapper}
        >
          <Image src="Emsculpt_Better_Aging.png" alt="EMSCULPT unterstützt Sie beim Better Aging" className={classes.imageSrc}/>
       </div> 
       Mit zunehmendem Alter fällt der Erhalt der Muskelmasse schwerer.  Bereits ab dem 30. Lebensjahr kommt es ohne Sport zu einer Abnahme der Muskelmasse. Diese wird in Fett umgewandelt. Dabei baut der Mensch bis zu ein Prozent Muskeln pro Jahr ab, ohne Sport büßt der Mensch so bis zum 80. Lebensjahr bis zu 40 Prozent seiner Muskelmasse ein.
       <br/> <br/>
      Dies hat Folgen für den Alterungsprozess, da die Muskulatur Stoffwechsel und Energieverbrauch positiv beeinflusst. In der Folge des Muskelabbaus nimmt die Freude an der Bewegung ab, man wird  schwächer und bewegt sich weniger, fördert damit den Abbau der Muskelmasse weiter, was in der Konsequenz ebenfalls den Abbau der Knochenmasse (Osteoporose) nach sich zieht. 
      <br/> <br/>
      Dies erhöht auch das Risiko für Stürze oder Knochenbrüche. Die körperliche Fitness und die  Muskelreserven sind dabei auch mitentscheidend für die Regeneration bei Krankheit oder Verletzungen.
      <br/> <br/>
      EMSCULPT NEO kann Ihnen dabei helfen, diesen Kreislauf zu stoppen. Dabei können auch untrainierte oder ältere Menschen Ihre Muskulatur mit Hilfe von EMSCULPT NEO® wieder stärken und so den Einstieg in ein aktiveres Leben mit mehr Lebensfreude ermöglichen. EMSCULPT NEO®  
      kann hier auch Rehabilitationsmaßnahmen nach schweren Verletzungen oder längerer Krankheit unterstützen.
      <br/> <br/>
      Die Behandlung mit EMSCULPT NEO® ersetzt dabei nicht die regelmäßige körperliche Aktivität. Jedoch gerade in Zeiten, die die Möglichkeiten für Sport und andere Aktivitäten stark einschränken, kann EMSCULPT NEO® aber wirksam dazu beitragen, Ihre körperliche Fitness zu erhalten oder wieder herzustellen und somit Ihren Alterungsprozess positiv zu beeinflussen.

      </Typography>
      </article>
     </Container>
  )
}

EmsculptNeoBetterAging.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoBetterAging)
