import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"




const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "45vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 20,
    bottom: 20,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoBodyBuilding(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-MEHR-ERFOLG-BEIM-BODYBUILDING" aria-label="Steigern Sie Ihre Erfolge beim Body Building">
        <Typography
          variant="h4"
          marked="center"
         /*  className={classes.title} */
          component="h2"
        >
      Mehr Erfolg beim Body-Building
        </Typography>
        <article>
      <Typography    component={'article'} variant={'body2'}  align="justify">
        <div      
          className={classes.imageWrapper}
        >
          <Image src="Emsculpt_POST_October-Calendar_13102020_EN100.png" alt="EMSCULPT Erfolg beim Body Building" className={classes.imageSrc}/>
       </div> 
       Die Anwendungen von EMSCULPT NEO® sind so vielfältig, wie es auch die Funktion der Muskeln ist. 
       Dies Muskeln bilden die Basis für unser Wohlbefinden. Zusammen mit den Knochen, Gelenken, Sehnen und Bändern bilden sie den Stütz- und Bewegungsapparat des Menschen. 
       Nicht zuletzt können Sie mit EMSCULPT NEO® auch Ihr Bodybuilding Training effektiv  steigern und dabei eventuell Ihre Definition auf ein neues Niveau heben. 
       Dabei kann EMSCULPT NEO bei der Vorbereitung auf einen Wettkampf unterstützen oder aber auch nur dazu dienen, ihre persönlichen Ziele zu erreichen. 
      </Typography>
      </article>
     </Container>
  )
}

EmsculptNeoBodyBuilding.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoBodyBuilding)
