import React from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import { blue } from '@material-ui/core/colors';
import Typography from "./Utils/Typography"
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'



const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: "4px solid currentColor",
    borderRadius: 0,
    height: "auto",
    padding: theme.spacing(2, 5),
  },
  zeitnehmen: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
})

function EmsculptHerstellerInformationenBTL(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-HERSTELLERINFO-VON-BTL" aria-label="Herstellerinformationen zu EMSCULPT NEO von BTL">
        <Typography
          variant="h4"
          marked="center"
          /* className={classes.title} */
          component="h2"
        >
        Hersteller&shy;informationen zu EMSCULPT NEO®
        </Typography>

      <aside>
      <Typography   component={'article'} variant={'body2'}  align="justify">
      <blockquote>
      <strong>EMSCULPT NEO®</strong>  ist für die Behandlung von der <strong>Adipositas</strong> durch <strong>Fettabbau</strong> durch neuromuskuläre Stimulation, hochfrequenzinduzierte <strong>Lipolyse</strong> und Erhöhung der Durchblutung zugelassen. 
      <strong>EMSCULPT NEO®</strong> ist eine Weiterentwicklung des <strong>EMSCULPT</strong> und strahlt gleichzeitig Radiofrequenz und hochintensive elektromagnetische Energie ab.
      Mittels 7 klinischer Studien wurde mit den renommiertesten wissenschaftlichen Methoden nachgewiesen, daß EMSCULPT NEO  konsistent beim <strong>Fettabbau</strong> und <strong>Muskelaufbau</strong> wirkt.
      Die obe gezeigten Daten repräsentieren die durchschnittlichen Ergebnisse von 3 klinischen EMSCULPT NEO-Studien:<br/>
      <ol>
        <li><strong>Abdominal toning</strong> and  reduction of  <strong>subcutaneous fat</strong> with combination of novel radiofrequency treatment and HIFEM procedure - MRI scan study. Jacob C. et al. Präsentiert auf dem virtuellen ASDS 2020-Meeting.</li>
        <li>Radiofrequency heating and HIFEM delivered simultaneously - the first shamcontrolled randomized trial. Katz B. et al. Präsentiert auf dem virtuellen ASDS 2020-Meeting.</li>
        <li>Ultrasound evaluation of the simultaneous RF and HIFEM treatments on human fat tissue. Denkova R. Quelle: US-Food and Drug Administration (US-amerikanische Zulassungsbehörde). 510(k) Pre-market Notification: K192224. Online veröffentlicht am 5. Dezember 2019.</li>
      </ol>
      Ergebnisse und Patientenerfahrung können variieren. Mehr Informationen zu EMSCULPT NEO finden Sie auch beim Hersteller
      <a href="https://btlaesthetics.com/de/btl-emsculpt-neo">BTL</a> oder nutzen laden Sie sich unsere Broschüre zu EMSCULPT NEO herunter:
      </blockquote>



      </Typography>
      </aside>

      <Button id="DOWNLOAD-EMSCULPT-NEO-BROSCHÜRE-BTL" aria-label="Download Herstellerinformationen (Broschüre) zu EMSCULPT NEO von BTL im PDF Format" className={classes.button} href="/pdf/Emsculpt_Neo_BR_FAA_DE100_estetiese_bremen.pdf" download  >
        <Typography variant="h4" component="span" style={{ color: blue[800] }}>
         Broschüre <PictureAsPdfOutlinedIcon fontSize="large" style={{ color: blue[800] }}></PictureAsPdfOutlinedIcon>       </Typography>        
      </Button>
      <Typography variant="subtitle1" className={classes.zeitnehmen}>
        Wir nehmen uns gern Zeit für Sie. Wir freuen uns auf ein persönliches Gespräch.
      </Typography>

    </Container>
  )
}

EmsculptHerstellerInformationenBTL.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptHerstellerInformationenBTL)
