import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"

import EmsculptNeoZielgruppeCarousel from "./EmsculptNeoZielgruppeCarousel"



const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoZielgruppe(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-ZIELGRUPPE" aria-label="Ist EMSCULPT NEO für mich geeignet? EMSCULPT NEO Zielgruppe.">
        <Typography
          variant="h4"
          marked="center"
       /*    className={classes.title} */
          component="h2"
        >
        Ist EMSCULPT NEO® für mich geeignet?
        </Typography>

      <article>
      <Typography component={'article'} variant={'body2'} align="justify">
      Die EMSCULPT NEO® können Erwachsene bis zu einem BMI von 35 behandelt werden. Die Die Behandlung erfolgt nicht-invasiv, d.h. ohne OP und Spritzen. Für die Behandlung selbst ist weder eine besondere Vorbereitung noch eine Betäubung notwendig.
      Sie sollten eine Behandlung mit EMSCULPT NEO in Erwägung ziehen, wenn Sie eines der folgenden Ziele anstreben:
      <ul>
        <li>einfach nur einen Summer Body,</li>
        <li>eine elegante und sportliche Silhouette</li>
        <li>effektiv Abnehmen und ihren Körperstraffen,</li>
        <li>einen flachen und straffen Bauch mit definierten Muskeln an,</li>
        <li>einen Sixpack an und möchtem diesem Ideal näher kommen,</li>
        <li>Ihren Po (wieder) in Form bringen,</li>
        <li>einen "Brazilian Butt Lift", ohne Risiko,</li>
        <li>Ihr Bauchfett abbauen, um Ihre Risikofaktoren durch viszerales Fettgewebe zu reduzieren, </li>
        <li>nach längere Pause wieder aktiver werden</li>
        <li>dem natürlichen Muskelverlust durch Altern entgegenwirken (Ati-Aging) oder</li>
        <li>auch im Alter Ihre Bewglichkeit und Agilität erhalten (Better-Aging)?</li>
      </ul>
      Sollten Sie eine dieser Fragen mit "Ja" beantworten, so ist EMSCULPT NEO® eine Möglichkeiten diesen Zielen effektiv und mit einem
      guten Preis-Lesitungsverhältnis näher zu kommen.
      <br/>
      <br/>
      <br/>
      <br/>

      </Typography>

{/*       <div      
          className={classes.imageWrapper}
        >
          <Image src="emsculpt_neo_fettabbau.JPG" alt="Abbau Fettzellen" className={classes.imageSrc}/>
       </div> */}

       <EmsculptNeoZielgruppeCarousel />
      </article>

    </Container>
  )
}

EmsculptNeoZielgruppe.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoZielgruppe)
