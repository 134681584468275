import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "./Utils/Typography"

import PoolVideo from "../assets/videos/Emsculpt_Neo_VIDEO_Moa-Animation_EN100.webm"
import PoolVideoAlt from "../assets/videos/Emsculpt_Neo_VIDEO_Moa-Animation_EN100.mp4"



const styles = theme => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: "flex",
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: "flex",
    justifyContent: "center",
   
    backgroundColor: "white",
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",   
  },
  button: {
    width: "100%",
  },
  imagesWrapper: {
    position: "relative",
  },

  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    maxWidth: 600,
  },

  
})

function EmsculptNeoFunctionEMSCULPTNEO(props) {
  const { classes } = props


  return (
    <Container className={classes.root} component="section" >
      <Grid container>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>


          <video    
            id="VIDEO-EMSCULPT-NEO-FUNKTIONSPRINZIP"
            aria-label="Video des Herstellers BTL Aesthetics, das das Funktionsprinzip von EMSCULPT NEO zeigt."
            preload="auto"        
            autoPlay
            muted
            loop            
            playsInline
            className={classes.dynamicVideo}
            poster="../assets/images/dynamicVidPlaceholder.jpg"
            width="90%"
          >
            <source src={PoolVideo} type="video/webm"/>
            <source src={PoolVideoAlt}  type="video/mp4"/>
          </video>  

        </Grid>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
        <Typography variant="h4" marked="center" align="center" component="h2">
        BTL Aesthetics: Wie funktioniert EMSCULPT NEO?
        </Typography >
        <article id="EMSCULPT-NEO-FUNKTIONS-PRINZIP" aria-label="Beschreibung des Funktionsprinzips von EMSCULPT NEO des Herstellers" >
         <Typography align="justify">           
         <cite>
         EMSCULPT NEO basiert auf einem Applikator, der gleichzeitig synchronisierte Radiofrequenz und HIFEM+-Energie abstrahlt.
         <br/><br/>
        Aufgrund der Radiofrequenzerwärmung steigt die Muskeltemperatur schnell um mehrere Grad. Die Muskeln werden auf die Belastung vorbereitet, ähnlich wie bei einer Aufwärmaktivität vor dem Training. In weniger als 4 Minuten werden im Unterhautfett Temperaturen erreicht, die Apoptose verursachen, d. h. bei denen Fettzellen dauerhaft beschädigt und langsam aus dem Körper entfernt werden. Klinische Studien zeigten eine 30%-ige Reduktion des subkutanen Fettes.*
        <br/><br/>
        Unter Umgehung der vom Gehirn gemachten Beschränkungen kontrahiert HIFEM+-Energie Muskelfasern im behandelten Bereich mit einer Intensität, die durch konventionelles Training nicht erzielbar sein würde. Extremer Stress zwingt den Muskel zur Anpassung, die zu einer Zunahme der Anzahl und des Wachstums von Muskelfasern und -zellen führt. Im Durchschnitt steigt das Muskelvolumen um 25%.*
         </cite>
        <br/>* Quelle: ( <a href="https://btlaesthetics.com/de/btl-emsculpt-neo">BTL Aesthetics</a>), hier finden Sie auch eine Liste der Studien.
         </Typography>
         </article>
        </Grid>               
      </Grid>
    </Container>
  )
}

EmsculptNeoFunctionEMSCULPTNEO.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoFunctionEMSCULPTNEO)
