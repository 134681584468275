import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"




const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoGegenanzeigen(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-BEHANDLUNGSVERLAUF" aria-label="Wie fühlt sich die Behandlung mit EMSCULPT NEO an? ">
        <Typography
          variant="h4"
          marked="center"
          /* className={classes.title} */
          component="h2"
        >
       Wie fühlt sich die Behandlung an?
        </Typography>

      <article>
      <Typography   component={'article'} variant={'body2'}   align="justify">
      Die Behandlung von empfindlichen Patienten kann am Anfang als leicht unangenehm wahrgenommen werden. Sie ist aber niemals schmerzhaft. Im Rahmen der Behandlung wird die Intensität manuell bis zu 100 % erhöht.  
      Während der Behandlung wechseln sich verschiedene Kombinationen von Dauerkontraktionen mit pulsationsartigen Phasen ab.  

      Es ist keine Nachsorge erforderlich. Typischerweise sind Sie unmittelbar nach Abschluss der Behandlung wieder voll geschäftsfähig und es entstehen keine Ausfallzeiten für Sie. Als Nebenwirkungen kann es zu Hautrötungen oder leichtem Muskelkater kommen.
      </Typography>
      </article>

      <Typography
          variant="h4"
          marked="center"
          /* className={classes.title} */
          component="h2"
          id="EMSCULPT-NEO-GEGENANZEIGE" aria-label="Was spricht gegen eine Behandlung mit EMSCULPT NEO? "
        >

        <div      
          className={classes.imageWrapper}
        >
          <Image src="Emsculpt_Neo_POST_Candice-Warner-part-1-F_EN100.png" alt="EMSCULPT NEO-entspannte Behandlungssituation" className={classes.imageSrc}/>
       </div> 

       Was spricht gegen eine Behandlung?
        </Typography> 
        <article>
      <Typography   component={'article'} variant={'body2'}   align="justify">
       
        Gegenanzeigen sind eine Schwangerschaft, Metallimplantate, Herzschrittmacher oder Kupferspiralen, aber auch Hautpiercings oder andere metallische Gegenstände, z.B. Gürtel. Zahnimplantate stellen dabei kein Problem dar. 

       

       </Typography> 
       </article>

 
  

 
  

    </Container>
  )
}

EmsculptNeoGegenanzeigen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoGegenanzeigen)
