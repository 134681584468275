/* import loadable from '@loadable/component' */
import React from "react"
import Layout from "../components/UI/Layout"
import Seo from "../components/Seo"
import EmsculptBremenHero from "../components/EmsculptBremenHero"
// import SemanticSideInformation from '../components/Semantic/SemanticSideInformation'

import EmsculptNeoPreise from "../components/EmsculptNeoPreise"
import EmsculptNEOFragenAntworten from "../components/EmsculptNEOFragenAntworten"
import EmsculptHerstellerInformationenBTL from "../components/EmsculptHerstellerInformationenBTL"
import EmsculptNeoMuskelaufbau from "../components/EmsculptNeoMuskelaufbau"
import EmsculptNeoAbbauFettzellen from "../components/EmsculptNeoAbbauFettzellen"
import EmsculptBremenEinleitung from "../components/EmsculptBremenEinleitung"
import EmsculptNeoBesteErgebnisse from "../components/EmsculptNeoBesteErgebnisse"
import EmsculptNeoZielgruppe from "../components/EmsculptNeoZielgruppe"
import EmsculptNeoGegenanzeigen from "../components/EmsculptNeoGegenanzeigen"
import EmsculptNeoProblemzonenKörperForming from "../components/EmsculptNeoProblemzonenKörperForming"
import EmsculptNEOWiedereinstiegInBremen from "../components/EmsculptNEOWiedereinstiegInBremen"
import EmsculptNeoBodyBuilding from "../components/EmsculptNeoBodyBuilding"
import EmsculptNeoBetterAging from "../components/EmsculptNeoBetterAging"
import EmsculptNeoGegenFett from "../components/EmsculptNeoGegenFett"
import EmsculptNeoRektusdiastase from "../components/EmsculptNeoRektusdiastase"
import EMSellaGegenInkontinenz from "../components/EMSellaGegenInkontinenz"
import EmsculptNeoGegenSchnarchen from "../components/EmsculptNeoGegenSchnarchen"
import EmsculptNeoUniqueBeiESTETIESE from "../components/EmsculptNeoUniqueBeiESTETIESE"
import EmsculptNeoFunctionEMSCULPTNEO from "../components/EmsculptNeoFunctionEMSCULPTNEO"
import EmsculptNeoVorherNachher from "../components/EmsculptNeoVorherNachher"
import Anschrift from "../components/Anschrift"
import Karte from "../components/Karte"
import ContactForm from "../components/ContactForm" 


/* const  EmsculptNeoPreise = loadable(() => import("../components/EmsculptNeoPreise"))
const  EmsculptNEOFragenAntworten = loadable(() => import( "../components/EmsculptNEOFragenAntworten"))
const  EmsculptHerstellerInformationenBTL = loadable(() => import( "../components/EmsculptHerstellerInformationenBTL"))
const  EmsculptNeoMuskelaufbau = loadable(() => import( "../components/EmsculptNeoMuskelaufbau"))
const  EmsculptNeoAbbauFettzellen = loadable(() => import( "../components/EmsculptNeoAbbauFettzellen"))
const  EmsculptBremenEinleitung = loadable(() => import( "../components/EmsculptBremenEinleitung"))
const  EmsculptNeoBesteErgebnisse = loadable(() => import( "../components/EmsculptNeoBesteErgebnisse"))
const  EmsculptNeoZielgruppe = loadable(() => import( "../components/EmsculptNeoZielgruppe"))
const  EmsculptNeoGegenanzeigen = loadable(() => import( "../components/EmsculptNeoGegenanzeigen"))
const  EmsculptNeoProblemzonenKörperForming = loadable(() => import( "../components/EmsculptNeoProblemzonenKörperForming"))
const  EmsculptNEOWiedereinstiegInBremen = loadable(() => import( "../components/EmsculptNEOWiedereinstiegInBremen"))
const  EmsculptNeoBodyBuilding = loadable(() => import( "../components/EmsculptNeoBodyBuilding"))
const  EmsculptNeoBetterAging = loadable(() => import( "../components/EmsculptNeoBetterAging"))
const  EmsculptNeoGegenFett = loadable(() => import( "../components/EmsculptNeoGegenFett"))
const  EmsculptNeoRektusdiastase = loadable(() => import( "../components/EmsculptNeoRektusdiastase"))
const  EmsculptNeoGegenSchnarchen = loadable(() => import( "../components/EmsculptNeoGegenSchnarchen"))
const  EmsculptNeoUniqueBeiESTETIESE = loadable(() => import( "../components/EmsculptNeoUniqueBeiESTETIESE"))
const  EmsculptNeoFunctionEMSCULPTNEO = loadable(() => import( "../components/EmsculptNeoFunctionEMSCULPTNEO"))
const  EmsculptNeoVorherNachher = loadable(() => import( "../components/EmsculptNeoVorherNachher"))
const  Anschrift = loadable(() => import( "../components/Anschrift"))
const  Karte = loadable(() => import( "../components/Karte"))
const  ContactForm = loadable(() => import("../components/ContactForm" )) 
*/



export default function Home() {
  return (
    <Layout>
     
     <Seo title="Bodyforming,Muskelaufbau und Fettabbau-ohne OP" />
      {/* <SemanticSideInformation/> */}
      <EmsculptBremenHero dynamic />
      <EmsculptBremenEinleitung/>
      <EmsculptNeoBesteErgebnisse/>
      <EmsculptNeoMuskelaufbau/>
      <EmsculptNeoAbbauFettzellen/>
      <EmsculptNeoZielgruppe />
      <EmsculptNeoGegenanzeigen />
      <EmsculptNeoProblemzonenKörperForming/>
      <EmsculptNEOWiedereinstiegInBremen/>
      <EmsculptNeoBodyBuilding />
      <EmsculptNeoBetterAging />
      <EmsculptNeoGegenFett/>
      <EmsculptNeoRektusdiastase/>
      <EMSellaGegenInkontinenz/>
      <EmsculptNeoGegenSchnarchen/>
      <EmsculptNeoUniqueBeiESTETIESE/>
      <Anschrift/>
      <Karte/>
      <EmsculptNeoPreise /> 
      <ContactForm />

      <EmsculptHerstellerInformationenBTL />
            
      <EmsculptNEOFragenAntworten />      
      <EmsculptNeoFunctionEMSCULPTNEO/>
    
     <EmsculptNeoVorherNachher />
    </Layout>
  )
}
