import React from "react"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"

import Container from "@material-ui/core/Container"

import Typography from "./Utils/Typography"


import Button from "../components/Utils/Button"
import { ButtonGroup } from "@material-ui/core"
import SmsIcon from '@material-ui/icons/Sms';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';


const styles = theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secondary.light,
    overflow: "hidden",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  h4: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
/*   curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
    opacity: 0.7,
  }, */
/*   button: {
    marginTop: theme.spacing(8),
  }, */
  button: {
    minWidth: 200,
    backgroundColor:"#01663b" ,    
    color: theme.palette.common.white,
    marginTop: theme.spacing(8),
  },
})

function Anschrift(props) {
  const { classes } = props

  return (
    <section className={classes.root} id="EMSCULPT-NEO-ANSCHRIFT" aria-label="Anschrift und Kontaktschaltflächen E-mail, Telefon, SMS">
      <Container className={classes.container}>
{/*         <img
          src={ProductCurvyLines}
          className={classes.curvyLines}
          alt="curvy lines"
        /> */}
          <Typography
          variant="h4"
          marked="center"
          className={classes.h4}
          component="h2"
        >
         EMSCULPT NEO® in Bremen Privatpraxis ESTETIESE
        </Typography>

{/*         <Typography
          variant="h4"
          marked="center"
          className={classes.h4}
          component="h2"
          style={{color: '#CDCDCD'}}
        >         
              Adresse:                      
        </Typography>    */}   
        
        <Typography   component={'article'} variant={'body2'}  >             
              &emsp; Privatpraxis ESTETIESE<br /> <br />
              &emsp; Dr. med. Anna Maria Müller<br />
              &emsp; Norderoog 1<br />
              &emsp; 28259 Bremen
        </Typography>   

         
              {/* <aside> */}
              <ButtonGroup color="primary" variant="outlined" aria-label="Schaltflächen zur Kontaktaufnahme per Telefon, SMS oder e-mail." orientation="vertical">
                  <Button
                  variant="contained"                  
                  className={classes.button}
                  startIcon={<EmailIcon/>}
                  // component={Link}
                  href={"mailto:info@extetiese.de?subject=Kontaktanfrage für EMSulpt NEO&body=Datenschutz: Ihre Daten werden nur für die Kontaktaufnahme verwendet und nicht gespichert. Bitte nehmen Sie mit mir Kontakt auf. Mein Anliegen ..."}
                  >
                  E-Mail: info@estetiese.de
                  </Button>
                  <Button
                  variant="contained"                  
                  className={classes.button}
                  startIcon={<CallIcon/>}
                  // component={Link}
                  href={"tel:016090623273"}
                  >
                  Tel.: +49 (0) 160 / 90623273
                  </Button>
                  <Button
                    variant="contained"                                        
                    className={classes.button}
                    startIcon={<SmsIcon/>}
                    // component={Link}
                    href={"sms:016090623273"}>                       
                    SMS: +49 (0) 160 / 90623273
                    </Button>                  
              </ButtonGroup>
              {/* </aside> */}
        
        
        <br/>
        
        {/* <aside> */}
          
            
 
          <Typography             
            variant="body1"
            gutterBottom
            align="justify"  >
              Anreise:              <br/>
          Ab Hauptbahnhof fahren Sie mit der Linie 1 oder 8 von Domsheide in Richtung Huchting (Roland-Center). 
          Dort steigen Sie um und nehmen den Bus 58 in Richtung Mittelshuchting bis zur Haltestelle Carl-Hurzig-Straße. 
          Gehen Sie ca. 150 Meter zurück. Die Strasse Norderoog 1 befindet sich gegenüber der Rückseite der Aral-Tankstelle.
          Unsere Praxis finden Sie im 1. Stock, rechter Hand."
          </Typography>

        
      </Container>
    </section>
  )
}

Anschrift.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Anschrift)
