import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"

import EmsculptNeoVorherNachherCarousel from "./EmsculptNeoVorherNachherCarousel"



const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoVorherNachher(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-VORHER-NACHHER" aria-label="EMSCULPT NEO Vorher-Nachher Bilder von BTL Aesthetics zur Verfügung gestellt. ">
        <Typography
          variant="h4"
          marked="center"
       /*    className={classes.title} */
          component="h2"
        >
        EMSCULPT NEO: Vorher-Nachher Bilder
        </Typography>

      <article>
      <Typography align="justify">
      Vom Hersteller BTL Aesthetics zur Verfügung gestellte Vorher-Nachher Bilder:<br/>
      <br/>
      <br/>
      <br/>

      </Typography>
           <EmsculptNeoVorherNachherCarousel />
      </article>

    </Container>
  )
}

EmsculptNeoVorherNachher.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoVorherNachher)
