import React from "react"
import PropTypes from "prop-types"
import { useTheme,withStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Typography from "./Utils/Typography"
import Image from "./Image"

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';




const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  media: {
    height: 140,
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "38vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 20,
    bottom: 20,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoUniqueBeiESTETIESE(props) {
  const { classes } = props
  const theme = useTheme()

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-CARNOSIN_YOHIMBIN" aria-label="Einmalig bei uns EMSCULPT NEO und Infusion mit CARNOSIN oder YOHIMBIN">
        <article>
      <Typography   component={'article'} variant={'body2'}   align="justify">


      <Card className={classes.root}  >
      <CardActionArea>
      <CardContent style={{backgroundColor: "#01663b", color: theme.palette.common.white}}>
      <Typography
          variant="h4"
          marked="center"
        /*   className={classes.title} */
          component="h2"
          
        >
      <div style={{color: theme.palette.common.white}}> BEI UNS UNIQUE!</div>
        </Typography>


                <div      
          className={classes.imageWrapper}
        >
          <Image src="EMSCULPT_POST_June-Calendar_25062020_EN100.png" alt="Activate your muscles EMSCULPT NEO and CANOSIN" className={classes.imageSrc}/>
       </div>       

        
 
          <Typography   component={'article'} variant={'body2'}  >
          <em>Auf Wunsch kombinieren wir die EMSCULPT NEO-Sessions mit Infusionstherapie:</em>
          <ul>
                <li>CARNOSIN- wenn der Muskelaufbau im Vordergrund steht sowie</li>
                <li>YOHIMBIN- wenn primär die Fettreduktion angestrebt wird.</li>
          </ul>

          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>


      </Typography>
      </article>
     </Container>
  )
}

EmsculptNeoUniqueBeiESTETIESE.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoUniqueBeiESTETIESE)
