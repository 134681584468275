import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"



const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "50vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptBremenEinleitung(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-BEI-ESTETIESE" aria-label="EMSCULPT NEO: ">
        <Typography
          variant="h4"
          marked="center"
         /*  className={classes.title} */
          component="h2"
        >
        Einmalig in Bremen
        </Typography>

      <article>
      <Typography  component={'article'} variant={'body2'}  align="justify">
        ESTETIESE ist eine Privatpraxis für <strong>Ästhetische Medizin</strong>, <strong>Anti-Aging</strong> und <strong>Medizinische Kosmetik</strong> unter der Leitung von Dr. med. Anna Maria Müller.
        Der personalisierte, ganzheitliche Ansatz von Estetiese zielt sowohl auf die <strong>Gesundheit</strong> als auch auf das <strong>Aussehen</strong> der Patienten ab. 
        Ob Krankheit, natürlicher Alterungsprozess oder Prävention, hier stehen ganzheitliche Behandlungskonzepte an der Spitze des medizinischen Fortschritts. 
        Die Praxis-Highlights sind u.a.:
        <ol> 
          <li><strong>EMSCULPT NEO</strong>: Muskelaufbau (+25%), Fettabbau (-30%), Behandlung der Rektusdiastase sowie Beseitigung von viszeralem Fett;</li>
          <li><strong>EMTONE</strong>: gegen alle Ursachen von Cellulite sowie zur Hautstraffung (+59% Kollagen, +64% Elastin,  +44% Hautverdickung);</li>
          <li><strong>EMSELLA</strong>: gegen Harninkontinenz, Blasenschwäche, zur Stärkung des sexuellen Empfindens;</li>
        </ol>
      <aside id="EMSCULPT-NEO-KLINISCHE-STUDIEN" aria-label="Klinische Studien zu EMSCULPT NEO, FDA Zulassung, Medical CE">
      Die Ergebnisse sind durch umfangreiche klinische Studien belegt. 
      Die Geräte verfügen jeweils über eine <strong>FDA-Zulassung</strong> und <strong>Medical-CE-Zertifizierung</strong>.<br/>
      </aside>
      <br/>
      <article id="EMSCULPT-NEO-erster-Anbieter-in-Bremen" aria-label="ESTETIESE ist der erste Anbieter in Bremen für EMSCULPT NEO">
      Die Privatpraxis ESTETIESE ist der erste und zur Zeit <strong>einzige Anbieter in Bremem</strong> der Ihnen diese neuen Behandlungsmöglichkeiten bietet.  
      Diese Seite informiert Sie über die Technologie des <strong>EMSCULPT NEO</strong> und die verschiedenen medizinischen Anwendungen. 
      Sollte Ihr Fokus auf dem Bereich <strong>Fitness</strong> und <strong>Body Shaping</strong> bzw. <strong>Body Conturing</strong> liegen, so besuchen Sie gerne auch unsere Seite 
      <a href="https://emsculpt-point.de"> EMSCULPT-POINT</a> oder eine unserer Social Media Seiten, wo Sie sich über weitere Aspekte informieren können.
      </article>

      <br/> <br/><br/>
      </Typography>

      <div      
          className={classes.imageWrapper}
        >
          <Image src="emsculpt_neo_less_fat_more_muscles.JPG" alt="30 % weniger Fett, 25 % mehr Muskeln" className={classes.imageSrc}/>
       </div>
      </article>
      

    </Container>
  )
}

EmsculptBremenEinleitung.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptBremenEinleitung)
