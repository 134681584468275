import React from 'react';
import PropTypes from "prop-types"
import Carousel from "react-material-ui-carousel"
import { withStyles } from "@material-ui/core/styles"

import Image from "./Image"


const styles = theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(9),
    },
    images: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexWrap: "wrap",
    },
    imageWrapper: {
      position: "relative",
      display: "block",
      padding: 0,
      borderRadius: 0,
      //height: "100vh",
      height: "80vh",
      width:"auto",
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: 240,
      },
      "&:hover": {
        zIndex: 1,
      },
/*       "&:hover $imageBackdrop": {
        opacity: 0.15,
      },
      "&:hover $imageMarked": {
        opacity: 0,
      },
      "&:hover $imageTitle": {
        border: "4px solid currentColor",
      }, */
    },
    imageSrc: {
       position: "absolute !important",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%", 
    },

  })

  
function EmsculptNeoZielgruppeCarousel(props)
{
    const { classes } = props
    var items = [
        {
            id:"1",
            name: "Summer Body",
            description: "EMSCULPT: Summer Body für den Urlaub",
            fname:"Emsculpt_0_SummerBody.png"

        },
        {
            id:"2",
            name: "Sportliche Silhouette",
            description: "EMSCULPT: einen sportliche Körper",
            fname:"Emsculpt_0_sportliche Silhouette.png"

        },        
        {
            id:"3",
            name: "Abnehmen und Straffen",
            description: "EMSCULPT: Abnehmen und Straffen",
            fname:"EMSCULPT_1_Abnehmen_und_Straffen.png"

        },
        {
            id:"4",
            name: "Effektiv Abnehmen",
            description: "EMSCULPT NEO: Effektiv Abnehmen und Jahre an Jugend zurück gewinnen",
            fname:"Emsculpt_Neo1_Effektiv_Abnehmen.png"
        },        
        {
            id:"5",
            name: "Flexibler und flacher Bauch",
            description: "EMSCULPT NEO: Flexibler und flacher Bauch",
            fname:"EMSCULPT_NEO_2_Flexibler_und_flacher_bauch.png"
        },
        {
            id:"6",
            name: "Auf dem Weg zum Sixpack",
            description: "EMSCULPT NEO: SIX PACK World",
            fname:"EMSCULPT_NEO_3_SIXPACK.png"
            
        },
        {
          id:"7",
          name: "Sixpack, weibliches Model",
          description: "EMSCULPT: SIX PACK World",
          fname:"Emsculpt_SIXPACK_WOMAN.png"          
        },
        {
            id:"8",
            name: "Bringen Sie Ihren Po in Form",
            description: "EMSCULPT: So kommt Ihr Po wieder in Form",
            fname:"Emsculpt_4_Po_in_Form.png"
        },
        {
            id:"9",
            name: "Sie wollen eine Brazlian Butt",
            description: "EMSCULPT: Brazilian Butt ohne OP und Spritzen, ohne Risiko",
            fname:"Emsculpt_5_brazilian_butt.png"
        },
        {
            id:"10",
            name: "den Bauchumfang reduzieren",
            description: "EMSCULPT NEO: den Bauchumfang reduzieren, Risikofaktoren abbauen",
            fname:"Emsculpt_Neo_Bauchumfang_reduzieren.png"
        },
        {
            id:"11",
            name: "Wieder neu Durchstarten",
            description: "EMSCULPT NEO: nach der Pause neu Durchstarten",
            fname:"Emsculpt_Neo_Wieder_loslegen.png"
        },
        {
            id:"12",
            name: "Beweglich bleiben",
            description: "EMSCULPT: Bewehglich bleiben",
            fname:"Emsculpt_Prevention.png"
        },        
        {
            id:"13",
            name: "Fit und Gesund bleiben",
            description: "EMSCULPT NEO: Fit und Gesund",
            fname:"Emsculpt_Better_Aging.png"
        },      
        {
            id:"14",
            name: "einfach in Form bleiben",
            description: "EMSCULPT: in Form bleiben",
            fname:"Emsculpt_POST_November-Calendar_1012020_EN100.png"
        },              
        
    ]

    return (
        <Carousel 
            autoPlay="true" animation= "slide" indicators="false"  interval="2000" navButtonsAlwaysVisible="false"
        >
            
            { items.map(item => (
                    <div height="80vh" key={item.id}>
                    <div      
                  className={classes.imageWrapper}
                >
                    {/* <Image src={item.fname} className={classes.imageSrc}/> */}
                    <Image src={item.fname} style={{ height: "100%", width: "100%" }} alt={item.name}  imgStyle={{ objectFit: "contain" }} />
                    </div>
                    </div>                
                ))
            }
            
        </Carousel>
    )
}


EmsculptNeoZielgruppeCarousel.propTypes = {
    classes: PropTypes.object.isRequired,
  }
  
  export default withStyles(styles)(EmsculptNeoZielgruppeCarousel)