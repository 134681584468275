import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"




const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "50vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 20,
    bottom: 20,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNEOWiedereinstiegInBremen(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-WIEDEREINSTIEG-INS-TRAINING-ERLEICHTERN" aria-label="Mit EMSCULPT NEO den Wiedereinstieg ins Training erleichtern.">
        <Typography
          variant="h4"
          marked="center"
     /*      className={classes.title} */
          component="h2"
        >
       Wiedereinstieg in das Training
        </Typography>
        <article>
      <Typography   component={'article'} variant={'body2'}  align="justify">
        <div      
          className={classes.imageWrapper}
        >
          <Image src="Emsculpt_POST_December-Calendar_14122020_EN100.png" alt="Glückliches Training" className={classes.imageSrc}/>
       </div> 
       Eine längere Trainingspause wirft einen schnell leistungsmäßig zurück. Die Zahl der Gründe für eine längere Trainingspause ist dabei Legion: längere Dienstreisen oder Phasen mit Überstunden, Grippe, Covid-19 oder ein Bänderriss. Nach einer längeren Unterbrechung ist ein Neustart angesagt. Dabei kann es frustrierend und demotivierend sein, wenn man zunächst auf einem deutlich niedrigerem Trainingsniveau als gewohnt wieder anfangen muss, da während der Sportpause ein spürbarer Abbau von Muskelmasse, einhergehend mit einem Verlust an Körperkraft, stattgefunden hat. Besonders bitter ist es, wenn man hier den trainierten Bauch oder Po schwinden sieht.
       <br/> <br/>
       Mit EMSCULPT NEO kann man den Wiedereinstieg nach einer Erkrankung erleichtern. Sowohl in Phasen intensiver Aktivität als auch geschlossener Fitness-Studios kann EMSCULPT NEO zur Erhaltung oder zum Ausbau der Muskulatur beitragen und gleichzeitig entstehenden Fettpolstern entgegenwirken.

      </Typography>
      </article>
     </Container>
  )
}

EmsculptNEOWiedereinstiegInBremen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNEOWiedereinstiegInBremen)
