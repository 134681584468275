import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"



const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoAbbauFettzellen(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-ABBAU_FETTZELLEN" aria-label="EMSCULPT NEO: ABBAU von Fettzellen">
        <Typography
          variant="h4"
          marked="center"
          /* className={classes.title} */
          component="h2"
        >
        Prinzip des Abbau von Fett&shy;zellen durch EMSCULPT NEO®         
        </Typography>

      <article>
      <Typography   component={'article'} variant={'body2'}  align="justify">
      Das Gerät kombiniert diese Behandlung mit einem Radiofrequenzfeld (RF), das zu einer Erwärmung des Gewebes führt. Dies bewirkt eine Steigerung der Behandlungseffizienz und zusätzlich einen Abbau der Fettzellen in der behandelten Zone. Daraus resultiert ein dauerhafter Verlust von durchschnittlich 30% der Fettzellen in der behandelten Region.
      </Typography>

      <div      
          className={classes.imageWrapper}
        >
          <Image src="emsculpt_neo_fettabbau.JPG" alt="Abbau Fettzellen" className={classes.imageSrc}/>
       </div>
      </article>

    </Container>
  )
}

EmsculptNeoAbbauFettzellen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoAbbauFettzellen)
