import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"




const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "58vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    },*/
  }, 
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 20,
    bottom: 20,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoGegenFett(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-ERFOLGREICH-ABNEHMEN" aria-label="mit EMNSCULPT NEO erfolgreich abnehmen">
        <Typography
          variant="h4"
          marked="center"
         /*  className={classes.title} */
          component="h2"
        >
      Gegen Fett und seine gesundheitlichen Auswirkungen
        </Typography>
        <article>
      <Typography   component={'article'} variant={'body2'}   align="justify">
        <div      
          className={classes.imageWrapper}
        >
          <Image src="Emsculpt_Neo_Bauchumfang_reduzieren.png" alt="EMSCULPT unterstützt Sie beim Abnehmen" className={classes.imageSrc}/>
       </div> 
      
       Ein Großteil des sichtbaren Bauches wird häufig durch viszerales Fett bedingt. Dieses Fett umgibt auch Ihre Organe, wie beispielsweise die Leber und den Darm. Es zeichnet sich dadurch aus, dass es  stoffwechselaktiv ist und unterschiedliche Botenstoffe freisetzt, die Einfluss auf Ihren gesamten Körper haben.
        <br/> <br/>
        Je mehr von dem viszeralen Fett vorhanden ist, desto mehr entzündungsfördernde Botenstoffe können auch gebildet werden und desto mehr steigt in der Folge das Risiko für Herz-Kreislauf-Krankheiten, Krebserkrankungen und Stoffwechselstörungen.
        <br/> <br/>
        In der Medizin geht man heute davon aus, dass diese Botenstoffe Einfluss auf den Blutdruck nehmen und die Freisetzung von Insulin beeinflussen können. Des Weiteren stellt Übergewicht auch einen Risikofaktor für Herzschwäche dar
        <br/> <br/>
        Zwar kann EMSCULPT NEO® alleine eine langfristige Ernährungsumstellung und regelmäßige körperliche Aktivität nicht ersetzen, bis zu einem BMI von 35 kann es Sie aber bei einer nachhaltigen Gewichtsabnahme unterstützen. Insbesondere die durch die Behandlung mit EMSCULPT NEO® bewirkte Reduktion des Fettgewebes wirkt hier schon risikominimierend. Durch den gleichzeitigen Aufbau Ihrer Muskulatur fällt Ihnen Bewegung wieder leichter, was wiederum die Lust an sportlichen Aktivitäten steigert.


      </Typography>
      </article>
     </Container>
  )
}

EmsculptNeoGegenFett.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoGegenFett)
