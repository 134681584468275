import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"



const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoMuskelaufbau(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-MUSKELAUFBAU" aria-label="EMSCULPT NEO: Aufbau von Muskelfasern">
        <Typography
          variant="h4"
          marked="center"
        /*   className={classes.title} */
          component="h2"
        >
        Prinzip des Muskel&shy;aufbau mit EMSCULPT NEO®         
        </Typography>

      <article>
      <Typography align="justify">
      Emsculpt NEO® ermöglicht in verschiedenen Körperzonen die Muskeln wirksam aufzubauen und das Fettgewebe (auch das gesundheitsschädliche viszerale Fett) effektiv abzubauen.
      EMSCULPT® NEO wirkt durch ein hochintensives und fokussiertes elektromagnetisches Feld (HIFEM/Tesla) auf die Muskelfasern. Diese werden dadurch in starke (supraphysiologische) Kontraktionen versetzt, die deutlich über das beim Sport erreichbare Maß hinausgehen. Studien belegen eine Zunahme der Dicke und Anzahl der Muskelfasern und Zellen um durchschnittlich  25%. 
      </Typography>

      <div      
          className={classes.imageWrapper}
        >
          <Image src="emsculpt_neo_muskelfaser.JPG" alt="Muskelfasern" className={classes.imageSrc}/>
       </div>
      </article>


    </Container>
  )
}

EmsculptNeoMuskelaufbau.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoMuskelaufbau)
