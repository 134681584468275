import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "./Utils/Typography"
import Image from "./Image"




const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
/*     "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    }, */
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 20,
    bottom: 20,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
})

function EmsculptNeoProblemzonenKörperForming(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-BEHANDLUNG-PROBLEMZONEN" aria-label="Bedandlung von Problemzonen mit EMSCULPT NEO">
        <Typography
          variant="h4"
          marked="center"
        /*   className={classes.title} */
          component="h2"
        >
       Behandlung von Problemzonen – Körperformung
        </Typography>
        <article>
      <Typography   component={'article'} variant={'body2'}   align="justify">
        <div      
          className={classes.imageWrapper}
        >
          <Image src="Emsculpt_Neo_POST_Candice-Warner-part-1-G_EN100.png" alt="Body shaping" className={classes.imageSrc}/>
       </div> 
        Sie ernähren sich  gesund und treiben regelmäßig Sport, trotzdem haben Sie Problemzonen am Bauch oder am Po? Oder möchten Sie Ihren Armen und Beinen mehr Kontur verleihen?  Dann könnte Ihnen eine Behandlung mit EMSCULPT NEO® zum gewünschtem Erfolg verhelfen.
        <br/> <br/>
        Mit EMSCULPT NEO® können Sie klarer definierte Muskeln am Bauch erreichen, dort die Fettpolster weiter reduzieren, die Oberarme kräftigen, Ihre Waden stärken und den Po strafen bzw. liften. Im Regelfall reichen 4 Behandlung pro Zone a 30 Minuten aus, um sichtbare Resultate zu erzielen. Dabei fühlen Sie voraussichtlich bereits ab der ersten Behandlung positive Effekte der Anwendung.  Die Behandlungsserie wird für Zeitfenster von 4 Wochen angesetzt, jeweils mit ca. 7 Tagen Abstand. Sichtbare Veränderungen können nach der 3. oder 4. Behandlung wahrgenommen werden. Der vollständige Effekt erscheint normalerweise erst etwa 2-3 Monate nach dem Abschluss der  Behandlungsserie und hält dann bis zu 12 Monaten an.  Auffrischungen sind jederzeit möglich und können das erreichte Ergebnis absichern und sogar verbessern. Empfohlen sind eine oder zwei Erhaltungsbehandlungen nach 6 bis 12 Monaten.
      </Typography>
      </article>
     </Container>
  )
}

EmsculptNeoProblemzonenKörperForming.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoProblemzonenKörperForming)
