import React from "react"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"

import Typography from "./Utils/Typography"


import MyMap from "../components/Utils/MyMap"

const styles = theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secondary.light,
    overflow: "hidden",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
/*   title: {
    marginBottom: theme.spacing(14),
  }, */
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
/*   curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
    opacity: 0.7,
  }, */
  button: {
    marginTop: theme.spacing(8),
  },
})

function Karte(props) {
  

  return (
/*     <section className={classes.root} id="EMSCULPT-NEO-KARTE" aria-label="EMSCULPT BREMEN auf der OSM Karte">
      <Container className={classes.container}> */
 
          
            <Typography display='block' component={'span'}  variant="body1" style={{width:"100%"}} >
          <MyMap   /> 
          </Typography>
          
/*       </Container>
    </section> */
  )
}

Karte.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Karte)
